<template>
    <div>
        <TheHeader/>

        <TheBanner hide-enquiry="true"/>

        <div class="container py-8 mx-auto text-center">
            <h1 class="pb-6 text-3xl poppin-bold md:text-4xl">Message Sending Successful!</h1>
            <p class="px-4 text-lg md:px-0 open-reg">
                Thank you for your kind interest in KTWO Trading. We will get back to you as soon as possible.
            </p>
                <router-link to="/" class="block w-48 mx-auto">
                <p class="block py-2 my-5 text-white bg-gray-700 rounded-lg open-semi">Back To Home</p>
            </router-link>
        </div>


        <!-- copyright -->
        <Thecopyright/>

    </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
import TheBanner from '@/components/TheBanner.vue'
import Thecopyright from "@/components/Thecopyright.vue";

export default {
    components: {
        TheHeader,
        TheBanner,
        Thecopyright,
    },
}
</script>

<style>

</style>